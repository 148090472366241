import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`photographer`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Local, Professional Photographer
          </h2>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.aboutPhotographer.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Capture Anything...</figcaption>
          </figure>
          <h3 id="dynamic-styles">Hi, my name is Ram Karamchandani</h3>
          <p>
            I am a Digital Photographer/Graphic Artist located in Austin, TX. My
            passion for photography began at a young age. I remember running
            around at my sister’s wedding taking photos of people with a small
            digital camera.
          </p>
          <p>
            Following that, I took a film photography class in middle school,
            graphic design classes all through high school and eventually
            completed my Associates Degree in Graphic Design in 2006. During my
            GD courses, I took a few Photography classes, which I then learned
            was my true passion.
          </p>
          <p>
            I decided to study Photography in 2009. Shortly after, I received a
            Bachelor’s degree in Fine Arts in Digital Photography from the
            Illinois Institute of Art - Schaumburg in 2011.
          </p>
          <p>I love capturing priceless moments of people and events!</p>
          <p>
            Interested in working together?{" "}
            <a href="/contact">Send me a message</a> and lets chat photography.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutPhotographer: file(relativePath: { eq: "about-photographer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
